/**
 * This is a module that contains code taken from the common.js file
 *
 * The purpose of this is to provide us with a place
 * where we can easily test the core parts of common.js
 *
 * In common.js we require this and delegate calls to the functions here.
 */
define('commonModule', ['jquery'], function($) {

  var _commonModule = {

    log: function(siteObj, message, type) {
      if (siteObj.debugFlag === true) {
        // You can use "log", "debug", "info", "error", etc. but will default to "log".
        type = type || 'log';

        if (window.console && window.console[type]) { // Check the console is open.
          window.console[type](message); // Log the message.
        }
      }
    },

    get_uri_params: function(str) {
      // if no parameter was passed, extract the GET part of our current URI
      if (!str && document.location.href.indexOf('?') > -1) {
        str = document.location.href.substr(document.location.href.indexOf('?'), document.location.href.length);
      } else if (!str) {
        // nothing to work with
        return {};
      }

      var map = {},
        parts = str.replace(/[?&#]+([^=&]+)=([^&#]*)/gi, function(m, key, value) {
          map[key] = value;
        });

      return map;
    },

    get_uri_param: function() {},

    gaEventTrack: function(category, action, label, data, url) {
      if (data) {
        label = 'Response: ' + label + ' | Request Data:' + JSON.stringify(data);
      }

      var eventCollection = ['_trackEvent'],
        args = [category, action, label, data, url];

      $.map(args, function(v) {
        if (v) {
          eventCollection.push(v);
        }
      });
    },

    loadScript: function(url) {
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      document.body.appendChild(script);
    }
  };

  // for unit tests
  return _commonModule;
});
